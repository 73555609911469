<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Orders</h6>
      <b-col cols="12" class="mb-2">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
            </div>
          </b-form-group>
        </div>
        <!-- table -->

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status + 1)">
                {{ status[0][props.row.status + 1] }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'sub_total'">
              €{{ parseFloat(props.row.sub_total + props.row.shipping - props.row.discount + props.row.service_charge).toFixed(2) }}
            </span>
            <span v-else-if="props.column.field === 'name'">
              <router-link :to="'/takeaway/shop/customers/' + props.row.user_id">{{ props.row.name }} #{{ props.row.user_id }}</router-link>
            </span>
            <span v-else-if="props.column.field === 'id'">
              <router-link :to="'/takeaway/shop/orders/' + props.row.id">{{ props.row.id }}</router-link>
            </span>
            <span v-else-if="props.column.field === 'token'">
              <span v-if="props.row.token != 'cash' && props.row.token != 'sage'">Stripe</span>
              <span v-else-if="props.row.token != 'cash' && props.row.token == 'sage'">Sage</span>
              <span v-else>Cash</span>
              <template v-if="props.row.origin"> ({{ props.row.origin }})</template>
            </span>
            <span v-else-if="props.column.field === 'updated_at'">
              {{ props.row.updated_at | moment("DD/MM/YYYY HH:mm") }}
            </span>
            <span v-else-if="props.column.field === 'order_time'">
              {{ props.row.order_time | moment("DD/MM/YYYY HH:mm") }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button type="submit" variant="primary" :to="'/takeaway/shop/orders/' + props.row.id" class="mr-50">
                <feather-icon icon="BookOpenIcon" />
              </b-button>
              <b-button type="submit" variant="danger" @click="hideOrder(props.row.id)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['500', '1000', '2500']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BCol, BRow, BButton } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Ripple from "vue-ripple-directive";
import _ from "lodash";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 500,
      dir: false,
      columns: [
        {
          label: "ID",
          field: "id",
          width: "80px",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Customer",
          field: "name",
        },
        {
          label: "User ID",
          hidden: true,
          field: "user_id",
        },
        {
          label: "Payment",
          field: "token",
        },
        {
          label: "Required By",
          field: "order_time",
          width: "200px",
        },
        {
          label: "Last Action",
          field: "updated_at",
          width: "200px",
        },
        {
          label: "Total",
          field: "sub_total",
          width: "120px",
        },
        {
          label: "Action",
          field: "action",
          width: "150px",
        },
      ],
      rows: [],
      searchTerm: "",
      status: [
        {
          0: "Refunded",
          1: "Queued",
          2: "Accepted",
          3: "Out for delivery",
          4: "Completed",
          5: "Failed",
        },
        {
          0: "danger",
          1: "primary",
          2: "info",
          3: "info",
          4: "success",
          5: "danger",
        },
      ],
      coupons: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "danger",
        1: "primary",
        2: "info",
        3: "info",
        4: "success",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    getCouponName(coupon_id) {
      if (coupon_id === null) return " - ";
      return _.find(this.coupons, { id: coupon_id }).title;
    },
    hideOrder(id) {
      useJwt
        .delete_order(id)
        .then(() => {
          useJwt
            .get_all_orders()
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Order Hidden`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully hidden order.`,
                },
              });
              let orders = [];
              for (let x = 0; x < response.data.data.length; x++) {
                if (response.data.data[x].deleted == 0) {
                  orders.push(response.data.data[x]);
                }
              }

              localStorage.setItem("userOrders", JSON.stringify(orders));
              return (this.rows = orders);
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't downlad your order data from the server.`,
                },
              });
            });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your order data from the server.`,
            },
          });
        });
    },
  },
  mounted() {
    useJwt
      .get_coupons()
      .then((response) => {
        let data = response.data.data;
        for (let x = 0; x < data.length; x++) {
          data[x].status = false;
        }

        localStorage.setItem("userCoupons", JSON.stringify(data));

        this.coupons = data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
    useJwt
      .get_all_orders()
      .then((response) => {
        let orders = [];

        for (let x = 0; x < response.data.data.length; x++) {
          if (response.data.data[x].deleted == 0) {
            orders.push(response.data.data[x]);
          }
        }

        localStorage.setItem("userOrders", JSON.stringify(orders));
        return (this.rows = orders);
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
